// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// $(document).ready(function() {
//   $('.datatable').DataTable({"paging":   false});
// });

import $ from 'jquery';
window.jQuery = $;
window.$ = $;
import select2 from 'select2';
import 'select2/dist/css/select2.css';
$(document).ready(function() {
  $('.select2').select2();
});
$(document).ready(function() {
  $("#puzzle_order_customer_id").on('change', function() {
    $.ajax({
      dataType: "json",
      cache: false,
      url: '/admin/subscriptions/?customer_id=' + $(this).val(),
      timeout: 5000,
      error: function(XMLHttpRequest, errorTextStatus, error) {
        alert("Failed to submit : " + errorTextStatus + " ;" + error);
      },
      success: function(data) {
        // Clear all options from course select
        $("#puzzle_order_subscription_id option").remove();
        var row = "<option>Select</option>";
        $(row).appendTo("#puzzle_order_subscription_id");
        // Fill course select
        $.each(data, function(i, j) {
          row = "<option value=\"" + j.id + "\">" + j.recharge_subscription_id + "</option>";
          $(row).appendTo("#puzzle_order_subscription_id");
        });
      }
    });
  });
  $("#puzzle_order_subscription_id").on('change', function() {
    $.ajax({
      dataType: "json",
      cache: false,
      url: '/admin/puzzles/',
      timeout: 5000,
      error: function(XMLHttpRequest, errorTextStatus, error) {
        alert("Failed to submit : " + errorTextStatus + " ;" + error);
      },
      success: function(data) {
        // Clear all options from course select
        $("#puzzle_order_puzzle_id option").remove();
        // Fill course select
        $.each(data, function(i, j) {
          var row = "<option value=\"" + j.id + "\">" + j.name + "</option>";
          $(row).appendTo("#puzzle_order_puzzle_id");
        });
      }
    });

  });
});